import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import LogoPreview from "./assets/logoPreview.png";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BiLinkExternal } from "react-icons/bi";
import { log10 } from "chart.js/helpers";

function CreatedTemplateLook(props) {
  const [mediaData, setMediaData] = useState(null);
  const [mediaType, setMediaType] = useState(null); // new state to track media type

  const {
    templateName,
    headerType,
    headerText,
    body,
    footer,
    headerSample,
    phoneNumberBtnText,
    urlBtnText,
    btn1,
    btn2,
    btn3,
  } = props.templateData;

  const mediaViewer = () => {
    if (props.media) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setMediaData(reader.result);
        setMediaType(props.media.type.startsWith("video") ? "video" : "image"); // set media type based on file type
      };
      reader.readAsDataURL(props.media);
    } else {
      setMediaData(null);
      setMediaType(null);
    }
  };

  useEffect(() => {
    mediaViewer();
  }, [props.media]);

  function makeTextBold(text) {
    const regex = /\*(.*?)\*/g;
    if (regex.test(text)) {
      const boldText = text.replace(
        regex,
        (match, capturedGroup) =>
          `<span style="font-weight: bold;">${capturedGroup}</span>`
      );
      return boldText;
    }
    return text; // No bold content found, return the original text
  }

  function makeTextItalic(text) {
    const regex = /_([^_]+)_/g;
    if (regex.test(text)) {
      const italicText = text.replace(
        regex,
        (match, capturedGroup) =>
          `<span style="font-style: italic;">${capturedGroup}</span>`
      );
      return italicText;
    }
    return text; // No italic content found, return the original text
  }

  console.log(body);

  return (
    <div id="templateLook">
      <div className="d-flex preview">
        <div className="previewLogo mt-3 ms-5">
          <img src={LogoPreview} alt="logo preview" className="mt-2 ms-2" />
        </div>
        <h5 className="text-center mt-4 ms-3">Preview</h5>
      </div>

      <div className="myCard">
        <div className="templateLook">
          {mediaData && mediaType === "image" && (
            <Image
              src={mediaData}
              alt="File Preview"
              className="mt-3"
              thumbnail
            />
          )}
          {mediaData && mediaType === "video" && (
            <video
              src={mediaData}
              alt="File Preview"
              className="mt-3 ms-5"
              controls
              style={{ height: "31vmax" }}
            />
          )}
          <h6 className="mt-1 previewHeader ">
            {headerType === "Text"
              ? headerSample.length > 0
                ? headerText.replace("{{v}}", `${headerSample}`)
                : headerText.replace("{{v}}", `{{v}} enter sample`)
              : ""}
          </h6>
          <p className="ms-4">{body}</p>

          <p className="footerText mx-3 "> - {footer}</p>
          <hr />

          <div className="actionButtons text-center">
            {urlBtnText && (
              <p>
                <BiLinkExternal className="me-2" />
                {urlBtnText}
              </p>
            )}
            {phoneNumberBtnText && (
              <p>
                <BsFillTelephoneFill className="me-2" />
                {phoneNumberBtnText}
              </p>
            )}

            {btn1 && <p>{btn1}</p>}
            {btn2 && <p>{btn2}</p>}
            {btn3 && <p>{btn3}</p>}
          </div>
        </div>
      </div>
      {props.preview ? null : (
        <div>
          <h3 className="mt-5 mx-5 px-3 text-center">
            Whatsapp Cloud API takes 24 hours to approve your template
          </h3>
        </div>
      )}
    </div>
  );
}

export default CreatedTemplateLook;
