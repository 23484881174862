import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./template.css";

const SelectTemplateHeader = ({ showtext, category, whichtemplate }) => {
  const [activeTab, setActiveTab] = useState("Accepted");

  const handleTabClick = (tab) => {
    whichtemplate(tab);
    setActiveTab(tab);
  };

  const handleCategoryChange = (event) => {
    // console.log(event.target.value);
    category(event.target.value);
  };

  // this component shows the option based on the selected category
  function ToggleComponent() {
    if (showtext === "MARKETING") {
      return (
        <>
          <option value="MARKETING">Marketing</option>
          <option value="UTILITY">Utility</option>
        </>
      );
    } else {
      return (
        <>
          <option value="UTILITY">Utility</option>
          <option value="MARKETING">Marketing</option>
        </>
      );
    }
  }

  const tabs = ["Accepted", "Under Review", "Rejected"];

  return (
    <div className="custom-margin-top">
      <div className="container mt-20">
        <div className="row  w-100 background-container">
          <div className="mt-4 p-2">
            <h3 className="research-heading text-center">
              {showtext} Templates
            </h3>
            <p className="research-content text-center">
              Browse through the templates below and select the one you like to
              send messages to your customers.
              <br />
              You can filter the templates by status or category to find exactly
              what you need.
            </p>
          </div>
          <div className="col-md-6 w-75 mb-4">
            <div
              className="rounded p-1 border w-100 d-flex"
              id="selectableTabs"
            >
              {tabs.map((tab) => (
                <div
                  key={tab}
                  className={`flex-grow-1 ms-1  text-center accepted rounded ${
                    activeTab === tab ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(tab)}
                >
                  <p className="mt-2">{tab}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-6 w-25">
            <select
              className="form-select w-100 marUtilAuth_Changer"
              onChange={handleCategoryChange}
            >
              <ToggleComponent />
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTemplateHeader;
