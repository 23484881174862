import { useEffect, useState, useContext } from "react";
import { Bar } from "react-chartjs-2";
import { mymetrics } from "../../App";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
Chart.register(CategoryScale, LinearScale, BarElement, Legend, Title, Tooltip);

// This is the metrics graph component
function MetricGraph({ date }) {
  console.log(date);

  const [metrics, setMetrics] = useContext(mymetrics);
  const [loading, setLoading] = useState(true);
  const [labels, setLabels] = useState([]);
  const [templatesNames, setTemplatesNames] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (metrics && metrics["Not From Webhooks"]) {
          // Extract template names and structure data
          setTemplatesNames(templateNameExtractor(metrics["All"]));
          const resultArray = await metricDataStructuring(metrics["All"]);

          console.log("The result array is ", resultArray);

          // Set labels and stop loading
          setLabels(resultArray);
          setLoading(false);
        } else {
          console.log("No metrics data received");
          setLoading(false); // Stop loading even if data isn't available
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Stop loading in case of error
      }
    };

    fetchData();
  }, [metrics]);

  const templateNameExtractor = (data) => {
    const groupedData = Object.entries(data).reduce(
      (result, [date, templates]) => {
        const formattedDate = date.substring(0, 10);
        const templateName = Object.keys(templates)[0];

        const existingItem = result.find((item) => item.date === formattedDate);
        if (existingItem) {
          existingItem.templateName.push(templateName);
        } else {
          result.push({
            date: formattedDate,
            templateName: [templateName],
          });
        }

        return result;
      },
      []
    );

    return groupedData;
  };

  const metricDataStructuring = (data) => {
    const templateCounts = {};
    Object.keys(data).forEach((timestamp) => {
      const currentDate = timestamp.substring(0, 10);

      if (!templateCounts[currentDate]) {
        templateCounts[currentDate] = {
          templatesSent: 0,
          sumAudience: 0,
          sumSuccess: 0,
          sumFailure: 0,
          read: {},
          response: {},
        };
      }

      Object.keys(data[timestamp]).forEach((templateType) => {
        templateCounts[currentDate].sumAudience +=
          data[timestamp][templateType][0]["size of audience"];
        templateCounts[currentDate].sumSuccess +=
          data[timestamp][templateType][0].success;
        templateCounts[currentDate].sumFailure +=
          data[timestamp][templateType][0].failure;

        templateCounts[currentDate].read[timestamp] =
          data[timestamp][templateType][0].read;
        templateCounts[currentDate].response[timestamp] =
          data[timestamp][templateType][0]["response ratio"];
      });
    });

    const resultArray = Object.keys(templateCounts).map((date) => ({
      date,
      sumAudience: templateCounts[date].sumAudience,
      sumSuccess: templateCounts[date].sumSuccess,
      sumFailure: templateCounts[date].sumFailure,
      read: templateCounts[date].read,
      response: templateCounts[date].response,
    }));

    return resultArray;
  };

  const datasets = [
    {
      label: "Total Audience",
      data: labels.map((label) => label.sumAudience),
      backgroundColor: "#0dc16b",
    },
    {
      label: "No of campaigns",
      data: templatesNames.map(
        (templatesSent) => templatesSent.templateName.length
      ),
      backgroundColor: "#f4c542",
    },
    {
      label: "Success",
      data: labels.map((label) => label.sumSuccess),
      backgroundColor: "#3f51b5",
    },
    {
      label: "Failure",
      data: labels.map((label) => label.sumFailure),
      backgroundColor: "#f44336",
    },
  ];

  const data = {
    labels: labels.map((obj) => obj.date),
    datasets: datasets,
  };

  const options = {
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Tracker",
      },
    },
  };

  const chartContent = !loading ? (
    labels.length === 0 ? (
      <p>No data is present</p> // Display this if labels array is empty
    ) : (
      <Bar data={data} options={options} />
    )
  ) : (
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );

  return <div className="mt-5 p-3">{chartContent}</div>;
}

export default MetricGraph;
