import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DashboardCards from "./cards";
function CardCarousel() {
  const cardData = [
    {
      title: "Why WhatsApp Over SMS and Email ?",
      subtitle: "Instant, Engaging, and Personal",
      text: "WhatsApp is trending because it provides real-time, personalized communication with high engagement rates. ",
    },
    {
      title: "Why WBS Official ?",
      subtitle: "Affordable Dashboard Solution",
      text: "At WBS Official, we provide high-quality WhatsApp API dashboard access at affordable prices. ",
    },
    {
      title: "Feature-Packed Dashboard",
      subtitle: "All-in-One Messaging Solution",
      text: "Enjoy features like scheduled messaging, analytics, template management, and user-friendly design.",
    },
    {
      title: "Security Features",
      subtitle: "End-to-End Encryption",
      text: "WhatsApp ensures your communication is secure with end-to-end encryption.",
    },
    // Add more card data as needed
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel responsive={responsive}>
      {cardData.map((card, index) => (
        <DashboardCards
          title={card.title}
          subtitle={card.subtitle}
          text={card.text}
          className={"me-3"}
          btnText1={"Show More"}
          link1={"#"}
        />
      ))}
    </Carousel>
  );
}
export default CardCarousel;
