import React, { useState, useContext, createContext, useEffect } from "react";
import Approved from "./showtemplates";
import SelectTemplateHeader from "./selectTemplateHeader";
import Header from "../../header";
import { RecentTemplateData, completeTemplateData } from "./callingTemplates";

// Creating a context
export const DataContext = createContext();

function SendMessage() {
  // Storing recent data
  let recent_data;

  recent_data = RecentTemplateData;

  const [whichtemplate, setwhichtemplate] = useState("Accepted");
  const [category, setcategory] = useState();

  // Checking which template is selected and updating data accordingly
  let Data;
  if (whichtemplate === "Rejected") {
    Data = completeTemplateData.REJECTED;
  } else if (whichtemplate === "Under Review") {
    Data = completeTemplateData.PENDING;
  } else if (whichtemplate === "Accepted") {
    Data = completeTemplateData.APPROVED;
  }

  function countTotalTemplates(approvedTemplates) {
    let totalMarketingCount = 0;
    let totalUtilityCount = 0;

    // Loop through all categories and count templates
    ["DOCUMENT", "TEXT", "IMAGE", "VIDEO"].forEach((category) => {
      if (approvedTemplates[category]) {
        approvedTemplates[category].forEach((template) => {
          if (template.category === "MARKETING") {
            totalMarketingCount++;
          } else if (template.category === "UTILITY") {
            totalUtilityCount++;
          }
        });
      }
    });
    totalUtilityCount > totalMarketingCount
      ? setcategory("UTILITY")
      : setcategory("MARKETING");
  }

  useEffect(() => {
    countTotalTemplates(completeTemplateData.APPROVED);
  }, [completeTemplateData]);

  return (
    // Providing data through context
    <DataContext.Provider value={Data}>
      <div className="col-10 myWidth" id="selectTemplate">
        <div>
          <Header title={"Select Template"} />
          <SelectTemplateHeader
            whichtemplate={setwhichtemplate}
            category={setcategory}
            showtext={category}
          />
        </div>
        {/* Rendering Approved component with category */}
        <Approved category={category} />
      </div>
    </DataContext.Provider>
  );
}

export default SendMessage;
