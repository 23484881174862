import React, { useEffect, useState } from "react";
import { Search } from "../selectTemplate/onlyTemplates";
import { Accepteddata } from "../selectTemplate/sendMessage";
import { create_object } from "../selectTemplate/onlyTemplates";
import { RecentTemplateData, completeTemplateData } from "./callingTemplates";

export const Show_recent = (serchElements) => {
  let valuesrow = [];
  let Accepteddata = completeTemplateData.APPROVED;

  Object.values(serchElements).map((searchQuery, index) => {
    {
      {
        Object.keys(Accepteddata).map((row, ind) => {
          let values = Search(searchQuery, Accepteddata[row]);
          //console.log(values, values.length);
          if (values.length > 0) {
            //  console.log(values,Object.values(values)[0],"eeeeeeeeeeeeeeeeeeeeee")
            values = Object.values(values)[0];

            valuesrow.push({ values: values, row: row });
            // console.log(valuesrow,"valuesrow")
          }
        });
      }
    }
  });
  // console.log(Object.values(valuesrow))
  return valuesrow;
};
//row, filteredTemplates, selected
export const Phonerecent_template = (template) => {
  let components, templateid, templatename;
  // console.log(typeof(template.template))
  Object.values(template.template).map((template) => {});
};
