import "react-multi-carousel/lib/styles.css";
import CardCarousel from "./ui/cardCarousel";
function InformationCenter() {
  return (
    <div className="my-4 mx-2">
      <h3 className="my-4">Information Center</h3>
      <CardCarousel />
    </div>
  );
}
export default InformationCenter;
