import React, { useState, useEffect } from "react";
import { persondata } from "../FetchedUserData";
import axios from "axios";
import "./loginsuccess.css";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, NavLink, Navigate, Link } from "react-router-dom";
import GoogleBtn from "../googleAuth";
import loginData from "./loginContent";
import FbLogin from "../facebookAuth";
import { apiRoutes } from "../../apiRoutes";
// this form checks the user data and send him to the appropriate end path
function Form(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState({
    email: "",
    id: "",
  });

  useEffect(() => {
    if (form) {
      console.log("The Updated Form", form);
      //sconsole.log(persondata, email, password);
    }
  }, [form]);

  const handleGoogleData = (data) => {
    if (data === "success") {
      setGoogleSignIn(true);
      show();
    }
  };

  const handleForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();

  const config6 = {
    method: "post",
    url: apiRoutes.login,
    data: JSON.stringify({
      email: email,
      password: password,
      id: uuidv4(),
    }),
  };

  //forgot password route
  const config5 = {
    method: "post",
    url: apiRoutes.forgotPassword,
    data: JSON.stringify({
      email: email,
      apiKey: "JayJaggu",
    }),
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      setErrorMessage("Incorrect username or password"); // if the form is empty this error will be shown
      return;
    } else {
      // this is to get the data from the database,
      // This below code leads you to either trail dashboard or the main dashboard

      axios(config6)
        .then((res) => {
          // console.log("the Response", res);
          props.setAuth(true);
          if (res.data[0].ACCOUNT_TYPE === "paid") {
            navigate("/");
          } else if (res.data[0].ACCOUNT_TYPE === "trail") {
            navigate("/daystrial");
          }
          //LoginPage/14daystrail
          // saving the success data details to fetchUserData component
          console.log(res.data);
          persondata.payableAmount = res.data[0].AMOUNT_DETAILS;
          persondata.companyname = res.data[0].COMPANY_NAME;
          persondata.EMAIL = res.data[0].EMAIL;
          persondata.permanenttoken = res.data[0].PERMANENT_TOKEN;
          persondata.WABA_ID = res.data[0].WABA_ID;
          persondata.phone_no = res.data[0].PHONE_NO;
          persondata.phoneNo_id = res.data[0].PHONENO_ID;
          persondata.appId = res.data[0].APP_ID;
          persondata.createdDateTime = res.data[0].CREATED_DATED;
          persondata.accountStatus = res.data[0].ACCOUNT_TYPE;
          setErrorMessage(null);
        })
        .catch((err) => {
          handleErrors(err); // errors are sent to handle errors
        });
    }
  };

  const handleErrors = (error) => {
    // console.log("The error we are getting is " + error);
    if (error.response) {
      if (error.response.data.ErrorMessage === "User does not exist") {
        navigate("/noAccount");
      } else if (
        error.response.data.ErrorMessage === "Invalid User Credentials"
      ) {
        setErrorMessage("Please check your password");
      } else if (
        error.response.data.ErrorMessage === "User status: trailExpired"
      ) {
        navigate("/trailExpired");
      }
    } else if (error === "forgot") {
      // console.log("forgotpassword");

      // here we are sending the user email to muleserver for forgot password operation

      axios(config5)
        .then((res) => {
          if (res.status === 200) {
            navigate("/login/forgotPassword");
          }
        })
        .catch((err) => {
          //  console.log(err, "err");
          if (err.response.status === 500) {
            navigate("/noAccount");
          }
        });
    }
  };

  useEffect(() => {
    if (errorMessage) {
      // console.log(errorMessage);
    }
  }, [errorMessage]);
  return (
    // this is the simple login form

    <div className="text-center">
      <div className="welcomeText">
        <h2> {loginData[1].title}</h2>
      </div>
      <p>{loginData[1].content}</p>
      {/* <button onClick={googleReg} className="googleBtn px-5 p-2">
        Log in with google
      </button> */}
      <div className="loginCard pt-4">
        <h3>Log in and join us!</h3>
        <div className="row mt-4 justify-content-center">
          <div className="col-2">
            <GoogleBtn
              setForm={setForm}
              myLocation="login"
              handleGoogleEvent={handleGoogleData}
              btnName="Log In With Google"
              setAuth={props.setAuth}
              //onClick={googleReg}
              //className="btn btn-large btn-success px-5 p-2"
            />
          </div>
          <div className="col-2">
            <FbLogin
              setForm={setForm}
              location="login"
              handleFbEvent={handleGoogleData}
              setAuth={props.setAuth}
            />
          </div>
        </div>

        <p className="mt-4"> -------------------- or ---------------------</p>
        {errorMessage ? (
          <div className="shadow p-3 mb-3 bg-white rounded text-success">
            {errorMessage}
          </div>
        ) : (
          ""
        )}

        <div className="loginForm">
          <form action="#">
            <div>
              <label htmlFor="floatingInput">Email address</label>
              <input
                type="email"
                className="form-control formInput"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className=" mt-3">
              <label htmlFor="password">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                className=" form-control formInput"
                placeholder="Enter your password"
                onChange={(e) => setPassword(e.target.value)}
              />{" "}
            </div>

            <div className="text-start mt-3 pb-3 ">
              <div className="row">
                <div className="form-check col-6">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    onChange={() => setShowPassword(!showPassword)}
                  />

                  <label
                    className="form-check-label mx-2"
                    htmlFor="flexCheckDefault"
                  >
                    Show Password
                  </label>
                </div>
                <Link
                  to="/login/forgotPassword"
                  className="secondary col-5"
                  onClick={(e) => {
                    e.preventDefault();
                    if (email === "") {
                      return alert("please keep mail in mail feild");
                    } else {
                      handleErrors("forgot");
                    }
                  }}
                >
                  Forgot password?
                </Link>
              </div>
            </div>
            <button className="btn largeButton mt-2" onClick={handleSubmit}>
              Sign In Now
            </button>
          </form>

          <div className="text-center">
            <div className="noAccount mt-4 pt-3 flex  d-flex">
              <p>Don’t have an account?</p>
              <a
                className="text-success ms-2 "
                onClick={() => navigate("/userregistration")}
              >
                <b>Register Now</b>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Form;
