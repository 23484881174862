import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function DashboardCards({
  title,
  subtitle,
  text,
  link1,
  link2,
  className,
  btnText1,
}) {
  const navigate = useNavigate();

  return (
    <Card className={`custom-card ${className}`} style={{ height: "14rem" }}>
      <Card.Title className="p-2 text-white ps-3">{title}</Card.Title>
      <Card.Body>
        <Card.Subtitle className="mb-2 text-muted">{subtitle}</Card.Subtitle>
        <Card.Text className="text-secondary">{text}</Card.Text>
        <div className="card-links">
          {link1 && (
            <Card.Link
              href="#"
              className="text-success custom-link"
              onClick={() => navigate(link1)}
            >
              {btnText1}
            </Card.Link>
          )}
          {link2 && (
            <Card.Link
              href="#"
              className="text-success custom-link"
              onClick={() => navigate(link2)}
            >
              {link2}
            </Card.Link>
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

DashboardCards.defaultProps = {
  title: "Card Title",
  className: "",
  subtitle: "Card Subtitle",
  text: "This is some quick example text to build on the card title and make up the bulk of the card's content.",
  link1: "Card Link",
  link2: "Another Link",
};

export default DashboardCards;
