import React, { useState, useContext } from "react";
import { BiSearch } from "react-icons/bi";
import { RenderGrid } from "./onlyTemplates";
import { handleShow } from "./seeAll";
import "./template.css";
import { DataContext } from "./sendMessage";
import { completeTemplateData } from "./callingTemplates";

const Approved = (props) => {
  const select = useContext(DataContext);
  const category = props;

  const countTemplatesByCategory = () => {
    if (completeTemplateData.APPROVED) {
      const templateTypes = ["TEXT", "DOCUMENT", "IMAGE", "VIDEO"];

      // Loop over each template type and filter by the given category
      const counts = templateTypes.reduce((counts, type) => {
        // Check if completeTemplateData.APPROVED[type] exists
        if (completeTemplateData.APPROVED[type]) {
          counts[type] = completeTemplateData.APPROVED[type].filter(
            (template) => template.category === props.category
          ).length; // Count only those templates matching the category
        } else {
          counts[type] = 0; // If the type doesn't exist, set the count to 0
        }
        return counts;
      }, {});

      return counts;
    }
    return {}; // Return an empty object if completeTemplateData.APPROVED is undefined
  };

  const {
    TEXT: textTemplates,
    DOCUMENT: docTemplates,
    IMAGE: imgTemplates,
    VIDEO: vidTemplates,
  } = countTemplatesByCategory();

  const totalTemplates =
    textTemplates + docTemplates + imgTemplates + vidTemplates;

  const [activeTab, setActiveTab] = useState("ALL");
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleCards, setVisibleCards] = useState({
    TEXT: 3,
    IMAGE: 3,
    VIDEO: 3,
    DOCUMENT: 3,
  });

  const handleTabClick = (tab) => {
    const tabUpperCase = tab.toUpperCase();
    setActiveTab(tabUpperCase);
    handleShow(tabUpperCase, visibleCards, setVisibleCards);
  };

  if (select) {
    return (
      <div id="templateBodySection" className="bg-white">
        <div
          id="templateTypeSelector"
          className="d-flex justify-content-center"
        >
          <ul className="d-flex justify-content-center">
            {[
              { tab: "All", count: totalTemplates },
              { tab: "Text", count: textTemplates },
              { tab: "Image", count: imgTemplates },
              { tab: "Video", count: vidTemplates },
              { tab: "Document", count: docTemplates },
            ].map(({ tab, count }, index) => (
              <li
                key={index}
                className={`mx-4 px-3 text-center accepted rounded ${
                  activeTab === tab.toUpperCase() ? "active" : ""
                }`}
                onClick={() => handleTabClick(tab)}
              >
                {tab} ({count})
              </li>
            ))}
          </ul>
          <div className="ms-5">
            <div className="input-group">
              <input
                type="text"
                value={searchQuery}
                className="form-control"
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search templates"
                aria-label="Search templates"
              />
              <div className="input-group-prepend">
                <span className="input-group-text py-2" id="basic-addon1">
                  <BiSearch />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <RenderGrid
            category={category}
            searchQuery={searchQuery}
            setActiveTab={setActiveTab}
            visibleCards={visibleCards}
            setVisibleCards={setVisibleCards}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white p-2">
      <h1 className="mt-5 ms-1 mx-4 text-center">No templates are available</h1>
    </div>
  );
};

export default Approved;
