import React, { useRef, useState, useEffect, useContext } from "react";
import { Dropdown, DropdownButton, ButtonGroup, Button } from "react-bootstrap";
import * as XLSX from "xlsx";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Card from "../selectTemplate/eachTemplate";
import { OnlyTemplates } from "../selectTemplate/onlyTemplates";
import "./phonedata.css";
import Header from "../../header";
import { apiRoutes } from "../../../apiRoutes";
import NonBulkMessenger from "./nonBulkMessenger";
import Guidelines from "./guidelines";
import { persondata } from "../../../Authentication/FetchedUserData";
import useVisibility from "../../setVisibleHook";
import ChartComponent from "./Countfaluresuccess";
import { Collecting } from "./Variablecalculator";
import { RecentTemplateData } from "../selectTemplate/callingTemplates";
import FileUploadSection from "./bulkMessengerUploader";
import PopUpModal from "./ui/PopUpModal";

export let totalrecords;

const Phonedata = () => {
  const { tisVisible, toggle, show, hide } = useVisibility(false);
  const [messageSuccess, setMessageSuccess] = useState(null);
  const [manualEntries, setManualEntries] = useState([]);
  const [fileType, setFileType] = useState();
  const [selectedSenderType, setSelectedSenderType] = useState("Bulk"); // Messenger selector over here
  let records;

  const [columnLists, setColumnLists] = useState({});

  const [fileName, setFileName] = useState("File name");

  const location = useLocation();
  const inputRef = useRef();
  let cardData,
    lowertype,
    cout = 1;

  if (location.state) {
    cardData = location.state.myCategory;
    cardData.select = "one";
    const bodyData = cardData.bodyText.split("\n");
    lowertype = cardData.templateType.toLowerCase();
    cout += Collecting(bodyData);
    if (lowertype !== "text") {
      cout += 1;
    }
  }

  const handleSenderTypeSelect = (type) => {
    setSelectedSenderType(type);
  };

  const handleFileUpload = async (e, functionality) => {
    let file;
    functionality ? (file = e) : (file = e.target.files[0]);
    if (file && file.name.toLowerCase().endsWith(".xlsx")) {
      // console.log(file);
      setFileName(file.name);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const rows = XLSX.utils
          .sheet_to_json(sheet, { header: 1, defval: null })
          .filter((row) => row.some((cell) => cell !== null));
        if (rows.length > 0) {
          processSheetData(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      console.log(file);
      setFileType("wrongFile");
      toggle();
    }
  };

  const processSheetData = (data) => {
    console.log("Input data:", data);
    const totalVariables = countVariables(cardData.bodyText);
    // Ensure cout is correctly set, or set it to the length of columns if undefined
    const cout = data[0].length - 1; // Adjust this based on your actual cout logic
    totalVariables;
    // Define the columns array and slice it according to cout
    const columns = [
      "phoneNumber",
      "headerSample",
      ...Array.from({ length: totalVariables }, (_, i) => `bodyvar${i + 1}`),
    ];
    console.log(columns);
    // Initialize newColumnLists dynamically
    let newColumnLists = columns.reduce((acc, col) => {
      acc[col] = [];
      return acc;
    }, {});

    // Process data rows
    if (manualEntries.length === 0) {
      data.slice(1).forEach((row) => {
        columns.forEach((col, index) => {
          if (index < row.length) {
            newColumnLists[col].push(row[index]);
          }
        });
      });
    } else {
      manualEntries.forEach((entry) => {
        columns.forEach((col) => {
          if (entry[col] !== undefined) {
            newColumnLists[col].push(entry[col]);
          }
        });
      });
    }

    console.log("Processed column lists:", newColumnLists);
    setColumnLists(newColumnLists);
  };

  // this function is for singular messagener and sends data singularly
  const singularMessenger = async (data) => {
    console.log("Input data:", data);

    // Ensure `cout` is defined and represents the total number of variables needed
    const bodyVariableCount = 10; // Adjust this according to your needs

    const columns = [
      "phoneNumber",
      "headerSample",
      ...Array.from({ length: bodyVariableCount }, (_, i) => `bodyvar${i + 1}`),
    ];

    let newColumnLists = columns.reduce((acc, col) => {
      acc[col] = [];
      return acc;
    }, {});

    const variablePusher = (newColumnData, inputColumnData) => {
      inputColumnData.length > 0 && newColumnData.push(inputColumnData);
    };

    data.forEach(({ phoneNumber, ...variables }) => {
      newColumnLists.phoneNumber.push(phoneNumber);

      Object.keys(variables).forEach((key) => {
        const newKey = key.replace("variable", "bodyvar");
        variablePusher(
          newColumnLists[newKey] || (newColumnLists[newKey] = []),
          variables[key]
        );
      });
    });

    console.log("Processed newColumnLists:", newColumnLists);
    const config = configurer(newColumnLists);
    console.log("Configuration:", config);

    try {
      const response = await axios(config);
      setMessageSuccess(response);
      toggle();
      console.log("Response:", response);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  function countVariables(text) {
    const regex = /\{\{(\d+)\}\}/g; // matches {{number}}
    let match;
    let count = 0;
    while ((match = regex.exec(text)) !== null) {
      count++;
    }
    return count;
  }

  //this function tries to correct and adjust data according to the template output
  const configurer = (columnData) => {
    console.log(columnData);
    // console.log("configurer on the run");
    const templateType = cardData.templateType.toLowerCase();
    const headerSample = columnData.headerSample;
    const hasHeaderVariable = /{{\d+}}/.test(cardData.headerTxt);
    const bodyVariableCount = countVariables(cardData.bodyText);
    // console.log(hasHeaderVariable);
    // console.log(bodyVariableCount);

    // Check for template type and validate headerSample accordingly
    if (templateType === "text") {
      if (hasHeaderVariable && !headerSample) {
        return alertInvalidData(
          "For text templates with variables in the header, please provide a header sample."
        );
      } else if (!hasHeaderVariable) {
        columnData.headerSample = [];
        console.log(columnData.headerSample); // Replace headerSample with an empty array if there is no variable
      }
    }
    //  else if (["video", "image", "pdf"].includes(templateType)) {
    //   if (!headerSample) {
    //     return alertInvalidData(
    //       `For ${templateType} templates, the header sample should not be empty.`
    //     );
    //   }
    // }

    // Function to alert the user and return null to indicate invalid data
    const alertInvalidData = (message) => {
      alert(message);
      return null;
    };

    console.log(columnData);

    // Dynamically create the body variables based on the count of variables in body text
    const bodyVars = {};
    for (let i = 1; i <= bodyVariableCount; i++) {
      bodyVars[`bodyvar${i}`] =
        i <= bodyVariableCount ? columnData[`bodyvar${i}`] : [];
    }

    const config10 = {
      method: "post",
      url: apiRoutes.sendMessage,
      data: JSON.stringify({
        "Phone Numbers": columnData.phoneNumber,
        "Template Name": cardData.templateName,
        templateLanguage: cardData.language,
        Phone_Number_ID: persondata.phoneNo_id,
        permanentToken: persondata.permanenttoken,
        headerType: lowertype,
        headerSample: columnData.headerSample,
        ...bodyVars,
        WABA_ID: persondata.WABA_ID,
      }),
    };

    totalrecords = columnData.phoneNumber.length;
    // console.log(config10);
    return config10;
  };

  // sends the excel data to muleserver
  const sendExcelData = async () => {
    totalrecords = columnLists.phoneNumber.length;
    try {
      const config = configurer(columnLists);
      const response = await axios(config);
      toggle();
      console.log(response);
      setMessageSuccess(response);
    } catch (error) {
      console.error("Error sending data:", error);
      setMessageSuccess("500");
      toggle();
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener("click", () => {
        inputRef.current.value = "";
      });
    }
  }, []);

  return (
    <div className="col-10 myWidth">
      <Header title="Send Message Page" />
      <div className="text-center mb-5 mt-5">
        <h1>Message Campaigner</h1>
        <p>
          You can send messages from here to all your customers what you gotta
          do is upload the excel file with right details
        </p>
      </div>
      <div className="row mt-5 mx-5">
        <div className="my-1">
          <h4>The Selected Template</h4>
          <p className="ms-1">
            The template you have selected will be seen here
          </p>
        </div>

        <div>
          <div className="row">
            <div className="col">
              {cardData && <Card cardData={cardData} />}
            </div>
            <Guidelines variables={cout} cardData={cardData} />
          </div>
        </div>
      </div>

      <div className="row ms-5 mt-5">
        <h2>The Messenger Section</h2>
        <p>
          Here you can either select the Bulk Messenger or Simple Sender to send
          Messages to your customers
        </p>
        <div>
          <ButtonGroup>
            <Button
              variant={
                selectedSenderType === "Bulk" ? "primary" : "outline-primary"
              }
              onClick={() => handleSenderTypeSelect("Bulk")}
            >
              Bulk Message Sender
            </Button>
            <Button
              variant={
                selectedSenderType === "Singular"
                  ? "primary"
                  : "outline-primary"
              }
              onClick={() => handleSenderTypeSelect("Singular")}
              className="bg-dark ms-2 text-white"
            >
              Singular Message Sender
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="row col-12 mt-4">
        {selectedSenderType === "Bulk" ? (
          <FileUploadSection
            fileName={fileName}
            setFileName={setFileName}
            inputRef={inputRef}
            handleFileUpload={handleFileUpload}
            // faisuc={faisuc}
            sendExcelData={sendExcelData}
          />
        ) : (
          <NonBulkMessenger
            onDataSend={singularMessenger}
            allData={cardData}
            persondata={persondata}
          />
        )}
      </div>
      <PopUpModal
        data={messageSuccess}
        show={tisVisible}
        handleClose={hide}
        totalRecords={records}
      />
      {fileType === "wrongFile" && (
        <PopUpModal
          show={tisVisible}
          handleClose={hide}
          handleWrongFile={setFileType}
          title={"You have uploaded a wrong file"}
          body="Please upload an .xlsx file before submitting."
        />
      )}

      <div className="row mt-3 ms-4">
        <h4>The recently used templates are</h4>
        <div>
          {RecentTemplateData.length > 0 && (
            <div className="row">
              <OnlyTemplates
                filteredTemplates={RecentTemplateData}
                selected={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Phonedata;
