import { useState } from "react";
import useVisibility from "../../setVisibleHook";
import { btnsType } from "../data";
import { AiOutlineDelete } from "react-icons/ai";

// This component is used for adding call to action buttons to the template
function ActionButtons(props) {
  const { setData, data, handleForm } = props;
  const { show, tisVisible, hide, toggle } = useVisibility();
  const [btnHide, setBtnHide] = useState(false);
  const [customButton, setCustomButton] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  return (
    <div className="actionBtns">
      <div className="d-flex">
        <div>
          <h5>Buttons (Optional)</h5>
          <p>Add buttons for customer interaction and action.</p>
        </div>
        <div>
          <select
            onChange={handleForm}
            id="btnType"
            className="dropdown mt-3 px-2 py-2 spacing form-select text-white"
          >
            {btnsType.slice(0, 3).map((x, index) => {
              return (
                <option key={index} style={{ textTransform: "capitalize" }}>
                  {x}{" "}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      {data.btnType === "call to action" ? (
        <div className="spacing">
          {btnHide ? (
            ""
          ) : (
            <div className="websiteButton">
              <div className="d-flex justify-content-around">
                <div>
                  <label htmlFor="button">Button Type (Read Only) </label>{" "}
                  <br />
                  <input
                    type="text"
                    value={"Website"}
                    className="form-control mt-2"
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="button" className=" mb-2">
                    Button Text
                  </label>
                  <input
                    type="text"
                    name="urlBtnText"
                    id="urlBtnText"
                    className="form-control"
                    placeholder="enter Button Name"
                    onChange={handleForm}
                    maxLength={25}
                  />
                </div>
              </div>
              <div className="mb-2 mt-2 ">
                <label htmlFor="Website Url" className="ps-5 mt-2">
                  {" "}
                  Website Url{" "}
                </label>
                <input
                  id="url"
                  type="text"
                  placeholder="enter your website url"
                  className="form-control urlInput mt-2"
                  onChange={handleForm}
                />
              </div>
              {tisVisible ? (
                <button
                  className="removeButton btn mt-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setBtnHide(true);
                  }}
                >
                  {" "}
                  <AiOutlineDelete className="mb-1 me-1" /> Remove Website
                  Button
                </button>
              ) : (
                ""
              )}
            </div>
          )}

          {!tisVisible ? (
            <button
              className="btn callToActionBtn"
              onClick={(e) => {
                e.preventDefault();
                show();
              }}
            >
              Add Phone Number Button
            </button>
          ) : (
            ""
          )}

          {tisVisible ? (
            <div className="mt-4 websiteButton">
              <div className="d-flex justify-content-around">
                <div>
                  <label htmlFor="button">Button Type (Read Only) </label>{" "}
                  <br />
                  <input
                    type="text"
                    value={"Phone Number"}
                    className="form-control mt-2"
                    readOnly
                  />
                </div>
                <div>
                  <label htmlFor="button" className="mb-2">
                    Button Text
                  </label>
                  <input
                    type="text"
                    name="phoneNumberBtnText"
                    id="phoneNumberBtnText"
                    className="form-control"
                    placeholder="enter Button Name"
                    onChange={handleForm}
                    maxLength={25}
                  />
                </div>
              </div>
              <div className="mb-2 mt-2 ms-2">
                <label htmlFor="Website Url" className="ps-5 mt-2">
                  Phone Number
                </label>
                <input
                  id="phoneNumber"
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  placeholder="Enter your Phone Number"
                  className="form-control urlInput mt-2"
                  onChange={handleForm}
                />
              </div>
              <button
                className="removeButton btn mt-2"
                onClick={(e) => {
                  e.preventDefault();
                  hide();
                }}
              >
                {" "}
                <AiOutlineDelete className="mb-1 me-1" /> Remove Phone Button
              </button>
            </div>
          ) : (
            ""
          )}
          {tisVisible && btnHide ? (
            <button
              className="btn callToActionBtn"
              onClick={(e) => {
                e.preventDefault();
                setBtnHide(false);
              }}
            >
              Add Website Button
            </button>
          ) : (
            ""
          )}
        </div>
      ) : data.btnType === "None" ? (
        setData((prev) => ({
          ...prev,
          phoneNumber: "",
          url: "",
          btnType: "",
          phoneNumberBtnText: "",
          urlBtnText: "",
        }))
      ) : data.btnType === "custom button" ? (
        <div id="customButtons">
          <input
            type="text"
            className="form-control"
            placeholder="Custom button 1"
            onChange={handleForm}
            id="btn1"
          />
          {Array.from({ length: customButton }).map((_, index) => (
            <input
              key={`customButton${index + 1}`}
              type="text"
              className="form-control mt-2"
              placeholder={`Custom button ${index + 2}`}
              onChange={handleForm}
              id={`btn${index + 1}`}
            />
          ))}
          <p className="mt-1">{errorMessage}</p>
          <button
            onClick={(e) => {
              e.preventDefault();
              customButton < 2
                ? (setCustomButton(customButton + 1), setErrorMessage(""))
                : setErrorMessage("Only three custom buttons are allowed.");
            }}
          >
            Add Custom Button
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ActionButtons;
