import { useEffect, useState } from "react";
import { MakingCards } from "./makingCards";

function MetricCards({ templateData }) {
  const [cardsUpdate, setCardsUpdate] = useState(null);
  const [totalMetrics, setTotalMetrics] = useState(0);
  const [loading, setLoading] = useState(true);

  console.log("Receiving Data", templateData);

  useEffect(() => {
    if (templateData) {
      if (templateData[0] || templateData[0] === 0) {
        // Set total to sum of success and failure, or 0 if they don't exist
        const total = (templateData[0] || 0) + (templateData[1] || 0);
        setTotalMetrics(total);

        setCardsUpdate(templateData); // Extracting ratios or data for the cards
        setLoading(false); // Stop loading once data is available
      }
    } else {
      // If no templateData, stop loading and set values to zero
      setTotalMetrics(0);
      setCardsUpdate([0, 0, 0]); // Default values as 0
      setLoading(false);
    }
  }, [templateData]);

  return (
    <div id="metricCards" className="text-start">
      <h3>Activity Monitor</h3>
      <p>Here's what happened from the last month</p>

      <MakingCards
        cardType={"Success"}
        cardUpdate={cardsUpdate?.[0] || 0} // Show zero if data is unavailable
        loading={loading}
        totalMetrics={totalMetrics}
        type={"metricCards"}
      />

      <MakingCards
        cardType={"Failure"}
        cardUpdate={cardsUpdate?.[1] || 0} // Show zero if data is unavailable
        loading={loading}
        totalMetrics={totalMetrics}
        type={"metricCards"}
      />

      <MakingCards
        cardType={"Read"}
        cardUpdate={cardsUpdate?.[2] || 0} // Show zero if data is unavailable
        loading={loading}
        totalMetrics={totalMetrics}
        type={"metricCards"}
      />
    </div>
  );
}

export default MetricCards;
