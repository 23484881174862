import "bootstrap/dist/css/bootstrap.css";
import { RiDashboardLine } from "react-icons/ri";
import { RiAccountPinCircleFill } from "react-icons/ri";
import * as iconBi from "react-icons/bi";
import * as iconsi from "react-icons/si";
import * as iconri from "react-icons/ri";
import * as iconio from "react-icons/io";
import { NavLink } from "react-router-dom";
import Logo from "../Authentication/images/wbsLogo.svg";

function Sidebar() {
  return (
    <div className="col-lg-2 border sidebar">
      <nav>
        <img src={Logo} alt="main logo" className="brandIcon" />
        <div className="mt-3"></div>
        <ul className="menu list-group list-unstyled mt-3">
          <NavLink to="/" className="menuItem" activeclassname="active">
            <li>
              {" "}
              <span className="icon">
                <RiDashboardLine />
              </span>{" "}
              Dashboard
            </li>
          </NavLink>
          <NavLink
            to="/createTemplate"
            className="menuItem"
            activeclassname="active"
          >
            <li>
              <span className="icon">
                <iconBi.BiMessage />
              </span>
              Create Message
            </li>
          </NavLink>
          <NavLink
            to="/allTemplates"
            className="menuItem"
            activeclassname="active"
          >
            <li>
              <span className="icon">
                <iconBi.BiMessage />
              </span>
              Send Message
            </li>
          </NavLink>

          <NavLink to="/status" className="menuItem" activeclassname="active">
            <li>
              <span className="icon">
                <iconsi.SiStatuspage />
              </span>
              Message Status
            </li>
          </NavLink>

          <NavLink to="/chats" className="menuItem" activeclassname="active">
            <li>
              <span className="icon">
                <iconri.RiTeamFill />
              </span>
              ChatBot
            </li>
          </NavLink>
          <NavLink
            to="/myaccount"
            className="menuItem"
            activeclassname="active"
          >
            <li>
              <span className="icon">
                <RiAccountPinCircleFill />
              </span>
              My Account
            </li>
          </NavLink>
          <NavLink to="/" className="menuItem bg-danger border-25">
            <li>
              <span className="icon">
                <iconri.RiLogoutBoxFill />
              </span>
              Log Out
            </li>
          </NavLink>
        </ul>
        <div className="mt-5 plusPoint">
          <span className="display-6">
            <iconio.IoLogoWhatsapp />
          </span>
          <h5 className="mt-1">Made with Whatsapp Cloud Api</h5>
          <p>We are the best and the fastest software in the internet</p>
          <a href="/" className="btn btn-dark mt-3 knowmore">
            <span className="px-2">
              <iconio.IoLogoWhatsapp />
            </span>
            Know More
          </a>
        </div>
      </nav>
    </div>
  );
}

export default Sidebar;
