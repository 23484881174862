import React, { useContext } from "react";
import { mymetrics } from "../../../App";
import { RecentTemplateData } from "../../Messagingpanel/selectTemplate/callingTemplates";
import { OnlyTemplates } from "../../Messagingpanel/selectTemplate/onlyTemplates";
import MetricGraph from "../../metrics/metricsGraph";
import InformationCenter from "./informationCenter";
import DashboardCards from "./ui/cards";

function Media(props) {
  const [gettingMetrics, setGettingMetrics] = useContext(mymetrics);
  return (
    <>
      <div id="dashboard">
        <div className="row text-center">
          <div className="mediaBack text-white  bg-success">
            <h2 className="mx-3">Dashboard Centre</h2>
            <p className="mx-3">
              Hey there! Welcome to our Dashboard where managing your WhatsApp
              marketing is a breeze!
            </p>
          </div>
        </div>
        <div className="informationCenter">
          <InformationCenter />
        </div>
        <div className="recentTemplates my-3 mx-3 mt-3">
          <h3>Recently Created Templates</h3>
          <div className="my-4 ms-2">
            <div className="row">
              {/* cards in recent templates */}
              <DashboardCards
                className={"col-3"}
                title={"Create Template"}
                subtitle={"Create Suitable Template"}
                text={
                  "This lets you create reusable content that includes dynamic fields, so you can personalize each message"
                }
                btnText1={"Create Template"}
                link1={"/createTemplate"}
              />
              <div className="col-9">
                {RecentTemplateData.length > 0 && (
                  <OnlyTemplates
                    filteredTemplates={RecentTemplateData}
                    selected={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="analytics">
          <h3 className="mb-3">Recent Analytics - last 30 days</h3>
          <MetricGraph />
        </div>{" "}
        `
      </div>
    </>
  );
}

export default Media;
