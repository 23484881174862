import React from "react";

function IntialChatScreen() {
  return (
    <div className="" id="intialChatScreen">
      <h2>THe intial chat screen</h2>
      <p>Click on any chat to check the messages they have sent to you</p>
    </div>
  );
}

export default IntialChatScreen;
