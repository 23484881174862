import React, { useState } from "react";
import { Card, Button, Modal, Table } from "react-bootstrap";
import { persondata } from "../../Authentication/FetchedUserData"; // Sample user data

function Billing() {
  // Parse payment details if available, else use an empty object
  const paymentData = persondata?.payableAmount
    ? JSON.parse(persondata.payableAmount)
    : {};

  const [showModal, setShowModal] = useState(false);

  console.log("person data payment details", persondata?.payableAmount);

  // Calculate total pending amount, or set to 0 if no payment data
  const totalPendingAmount = Object.keys(paymentData).reduce((total, month) => {
    return !paymentData[month].isPaid
      ? total + paymentData[month].DueAmount
      : total;
  }, 0);

  // Get the months with pending payments, or an empty array if no data
  const pendingMonths = Object.keys(paymentData).filter(
    (month) => !paymentData[month].isPaid
  );

  // Format date for displaying in modal
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-IN", options);
  };

  return (
    <div className="col">
      <Card className="billingCard mx-2">
        <Card.Body>
          <Card.Title>Pending Amount</Card.Title>
          <Card.Text>
            This is the overall due amount for your account.
          </Card.Text>
          <Card.Text>
            <strong>Total Pending:</strong> ₹{totalPendingAmount}
          </Card.Text>
          <Card.Text>
            <strong>Pending for Months:</strong>{" "}
            {pendingMonths.length > 0 ? pendingMonths.join(", ") : "None"}
          </Card.Text>
          <Button variant="primary" onClick={() => setShowModal(true)}>
            Show More
          </Button>
        </Card.Body>
      </Card>

      {/* Modal for showing detailed payment information */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Month</th>
                <th>Due Amount (₹)</th>
                <th>Status</th>
                <th>Payment Date</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(paymentData).length > 0 ? (
                Object.keys(paymentData).map((month) => (
                  <tr key={month}>
                    <td>{month}</td>
                    <td>₹{paymentData[month].DueAmount}</td>
                    <td>{paymentData[month].isPaid ? "Paid" : "Pending"}</td>
                    <td>
                      {paymentData[month].isPaid
                        ? formatDate(paymentData[month].AmountRecievedDateTime)
                        : "N/A"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No payment data available.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Billing;
